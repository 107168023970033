import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'import-file-modal',
    templateUrl: './importFileModal.component.html',
    styleUrls: ['./importFileModal.component.scss']
})

export class ImportFileModal {
    
}
