import { Component, OnInit,ViewChild } from '@angular/core';
import { AuthenticateService } from 'src/app/services/authenticate.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import {ModalComponent} from 'angular-custom-modal';
import { FileService } from 'src/app/services/file.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss','../../components/importFileModal/importFileModal.component.scss']
})

export class FilterComponent implements OnInit{
    @ViewChild('filterModal') public filterModal:ModalComponent;
    @ViewChild('editFilterModal') public editFilterModal:ModalComponent;
    moreOptions:any = [];
    editOptions:any = {name:'',values:[]};
    filters:any = [];
    selectedField:string = '';
    newField:string = '';
    filterId:any;
    error:string = '';
    toggleInput:any;
    constructor(
        private authService:AuthenticateService,
        private router:Router,
        private sharedService:SharedService,
        private fileService:FileService,
        private spinner:NgxSpinnerService
        ){
        
    }

    ngOnInit(){
        //this.getUserProfile();
        this.getFilterFields();
    }
    getFilterFields(){
      this.spinner.show();
      this.filters = [];
      this.fileService.getColumnFilters().subscribe(res=>{
        let obj = res['data'];
        let obj2 = obj.values;
        Object.keys(obj2).map(dt=>{
          if(dt != '_id'){
            this.filters.push({name:dt,values:obj2[dt]});
          }
          if(dt == '_id'){
            this.filterId = obj2[dt]['$oid'];
          }      
        })
        this.spinner.hide();
      },err=>{
        this.spinner.hide();
      })
    }

    openModal(name){
        this.moreOptions = [];
        this.selectedField = name;
        this.newField = '';
        this.filterModal.open();
    }
    closeModal(){
        this.error = '';
        this.moreOptions = [];
        this.filterModal.close();
    }
    openEditModal(filter){
      let data = filter;
      this.editOptions.name = data.name;
      if(data.values.length > 0){
          data.values.map(dt=>{
            this.editOptions.values.push({name:dt,toggle:false});
         })
      }
      this.selectedField = filter.name;
      this.editFilterModal.open();
  }
  closeEditModal(){
      this.error = '';
      this.editFilterModal.close();
      this.editOptions = JSON.parse(JSON.stringify({name:'',values:[]}));
      
  }
    deleteOption(index){
      this.moreOptions.splice(index,1);
    }
    deleteEditOption(index){
      Swal.fire({
        title:'Are you sure?',
        text:'Do you really want to delete?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
        if(result.value){
          this.editOptions.values.splice(index,1);          
        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }
     }) 
    }

    editOption(i){
      this.editOptions.values[i].toggle = true;
    }
    doneEdit(i){
      this.editOptions.values[i].toggle = false;
    }

    addMoreOption(){
        this.error = '';
        if(!this.newField){
          this.error = 'Please add filter value';
          return;
        }
        if(this.newField.trim() == ''){
          this.error = 'Please add filter value';
          return;
        }
        this.newField = this.newField.replace(/^\s+|\s+$/g, '');
        let toggle;
        this.moreOptions.map(dt=>{
            if(dt.name == this.newField){
               toggle = true;
            }
        })
        if(!toggle){
        this.moreOptions.push({name:this.newField})
        }
        this.newField = '';
    }
    updateFilter(){
      let toggle;
      this.error = ''
      if(this.moreOptions.length == 0){
        this.error = 'Please add filter value';
        return;
      }
      this.filters.map(dt=>{
        if(dt.name == this.selectedField){
          this.moreOptions.map((dt2,i)=>{
            dt.values.map(dt3=>{
              if(dt3 == dt2.name){
                this.moreOptions.splice(i,1);
              }
            })
          })
        }
      })
        this.filters.map(dt=>{
          if(dt.name == this.selectedField){
            this.moreOptions.map(dt2=>{
              dt.values.push(dt2.name);
            })
          }
        })
        this.closeModal();
        this.newField = '';
        this.moreOptions = [];
        this.finalUpdate();
      }
      finalUpdate(){
        let data = {};
        this.filters.map(dt=>{
          data[dt.name] = [];
          data[dt.name] = dt.values;
        })
        this.spinner.show();       
        this.fileService.updateFilter(data).subscribe(res=>{
          this.spinner.hide();
          Swal.fire('success','Filter has been updated','success').then(ok=>{
          this.getFilterFields();
          });
        },err=>{
          this.spinner.hide();
          Swal.fire('Error!','Filter not updated','error');
        })
      }

     editFilter(){
       let toggleErr;
       this.error = '';
       if(this.editOptions.values.length > 0){
        this.editOptions.values.map(dt2=>{
          let nm = '';
          nm = dt2.name;
          if(!nm){
            this.error = 'Please add filter value';
            toggleErr = true;
            return;
          }
          if(nm.trim() == ''){
            this.error = 'Please add filter value';
            toggleErr = true;
            return;
          }
        })
      }

      if(this.editOptions.values.length == 0){
        this.filters.map(dt=>{
          if(dt.name == this.selectedField){
            dt.values = [];
          }
        })
        this.closeEditModal();
        this.finalUpdate();
        return;
      }
      
      if(!toggleErr){
        let toggle,toggle2;
        let arr = [];
        this.filters.map(dt=>{
         if(dt.name == this.selectedField){
           if(this.editOptions.values.length > 0){
               toggle = true;
               this.editOptions.values.map(dt2=>{
                 arr.push(dt2.name);
               })
             }
           }
       })
       this.filters.map(dt=>{
         if(dt.name == this.selectedField){
           toggle2 = true;
           dt.values = arr;
         }
       })
       if(toggle && toggle2){
       this.closeEditModal();
       this.finalUpdate();
       }
      }
       
     } 
}
