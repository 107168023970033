import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'change-password',
    templateUrl: './changePassword.component.html',
    styleUrls: ['./changePassword.component.scss','../profile/profile.component.scss']
})

export class ChangePassword {
    user:any = {password:'',current_password:'',password_confirmation:''};
    error:any;
    constructor(private userService:UserService){

    }

    save(){
        if(this.validate()){
            let obj = {user:this.user};
            this.userService.updatePassword(obj).subscribe(res=>{
                Swal.fire('success','Password has been changed successfully!','success');
            },err=>{
                Swal.fire('Error!','Password is not updated,please try again later','error');
            })
        }
    }

    validate(){
        this.error = {};
        if(!this.user.current_password){
            this.error = {value:'current_password',msg:'Current password is required'};
            return false;
        }
        if(!this.user.password){
            this.error = {value:'password',msg:'Password is required'};
            return false;
        }
        if(!this.user.password_confirmation){
            this.error = {value:'password_confirmation',msg:'Confirm password is required'};
            return false;
        }
        if(this.user.password != this.user.password_confirmation){
            this.error = {value:'password_confirmation',msg:'Password and confirm password not matched'};
           return false; 
        }
        return true;
    }
  
}
