import { Component, OnInit ,Pipe,PipeTransform, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { FileService } from 'src/app/services/file.service';
import Swal from 'sweetalert2';
import { ExcelService } from 'src/app/services/excel.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { NgxSpinnerService } from 'ngx-spinner';
declare var $:any;
import * as FileSaver from 'file-saver';
import {ModalComponent} from 'angular-custom-modal';
//declare function clickOutSideEvent():any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss', '../../components/importFileModal/importFileModal.component.scss']
})

@Pipe({name:'keys'})
export class DashboardComponent implements OnInit{
    @ViewChild('imporFileModal') public modal:ModalComponent;
    @ViewChild('duplicateDeleteModal') public duplicateDeleteModal:ModalComponent;
    fileCount:number = undefined;
    delete_all:boolean = false;
    selectedFileTypeForDuplicateDelete:string = 'IBM';
    uploading:any;
    selectedImportFile:any = '';
    fileText:any = 'Choose File';
    file:any;
    fileIds:any =[];
    file_type:any = {};
    fileName:string = 'Select File here (Excel, CSV, PDF)';
    fileToUpload:any = [];
    error:any;
    errors:any;
    page_no: number = 1;
    per_page: number = 10;
    per_page_arr: any = [10, 20, 50, 100];
    total_count:any = 0;
    filter:any = {};
    filteredList:any = [];
    columns:any = [];
    showClass:boolean = false;
    tableColumns:any = [];
    filterToggle: boolean = false;
    filter_hasCheck: boolean = false;
    filter_SelectCol: boolean = false;
    toggle:boolean = false;
    selectedColumns:any = [];
    selectedFileType:any = '';
    tempCols:any = [];
    searchText:string = '';
    fileId:any;
    deleteMsg:any = '';
    file_types:any = [];
    //filter
    globalSearch:any = '';
    paginationToggle:boolean = false;
    exportAllToggle:boolean = false;
    clearFilterToggle:boolean = true;
    datePickerToggle:boolean = true;
    dateError:any;
    sortBy:any = [];
    sortByName:string = '';
    filter_class:boolean = false;
    filter_columns:any = {main_category:[],sub_category:[],meter_flag:[],billed_type:[],conumer_connection_status:[],tariff:[]};
    selectedMainCategories:any = [];
    selectedSubCategories:any =[];
    selectedMeterFlags:any = [];
    selectedBilledTypes:any = [];
    selectedConumerConnectionstatus:any = [];
    selectedBillDate:any = '';
    fromDate:Date;
    toDate:Date;
    bill_year:any = [];
    bill_month:any = [];
    selectedBillMonth:any = [];
    selectedBillYear:any = [];
    selectedTariffs:any = [];
    consumerNo:string = '';
    scno:string = '';
    //
    //Bill_adjustment
    revised_month:string = '';
    revised_year:string = '';
    revised_month_error:string = '';
    revised_year_error:string = '';
    adjustment_remark:string = '';
    //
    //Bill_Revision
    approved_date:Date;
    revision_remark:string = '';
    //
    selectAllCheck:boolean = false;
    ibmFilterToggle:boolean = false;
    elinkFilterToggle:boolean = false;
    adjustmentFilterToggle:boolean = false;
    revisionFilterToggle:boolean = false;
    rowsSelected:number = 0;
    csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Customer Billing Details:',
        useBom: true,
        noDownload: false,
        headers: []
      };
      exportItems:any = [];
      selectedExportFile:any;
    constructor(
        private sharedService:SharedService,
        private fileService:FileService, 
        private router:Router,
        private excelService:ExcelService,
        private spinner:NgxSpinnerService
        ){
           
    }
    ngOnInit(){
        this.exportItems = [{name:'Csv',label:'csv'},{name:'Excel',label:'Excel'}]
        this.file_types = [{name:'IBM'},{name:'E-LINK'},{name:'BILL_ADJUSTMENT'},{name:'BILL_REVISION'},{name:'IBM-ELINK'}];
        this.file_type = this.file_types[0];
        this.selectedFileType = 'IBM';
        if(this.sharedService.file_type){
            this.file_types.map(dt=>{
                if(dt.name == this.sharedService.file_type){
                    this.file_type = dt;
                    }
            })
        }
        this.checkFileUploadProcess();      
    }
    checkFileUploadProcess(){
        let obj;
        obj = this.sharedService.getSideKiqId();
        if(obj && obj.sidekiqId){
            this.spinner.show(); 
            if(obj.processName == 'file_upload'){
                this.sharedService.updateLoaderEvent({message:'File upload in progress, please wait...'});
            }else{
                this.sharedService.updateLoaderEvent({message:'Delete duplicate records in progress, please wait...'});
            }               
            this.uploading = setInterval(()=>{this.checkSidekiqProcess()},5000);
        }else{
            this.loadFileData();
        }
    }
    loadFileData(){
        this.adjustCssDropDown();
        this.getPage(1);
        this.getUniqueFilterColumns();
        this.addYearAndMonthList();
    }
    checkSidekiqProcess(){
        let obj;
        obj = this.sharedService.getSideKiqId();
        if(obj && obj.sidekiqId){
            this.fileService.checkFileUploadProcessing(obj.sidekiqId).subscribe(res=>{
            let data = res;
            if(data['success'] && data['status'] == 'progress'){
                this.spinner.show();
                if(obj.processName == 'file_upload'){
                    this.sharedService.updateLoaderEvent({message:'File upload in progress, please wait...'});
                }else{
                    this.sharedService.updateLoaderEvent({message:'Delete duplicate records in progress, please wait...'});
                }                
            }
            if(data['success'] && data['status'] == 'completed'){
                this.spinner.hide();
                clearInterval(this.uploading);
                if(obj.processName == 'file_upload'){
                    Swal.fire('success','File has been uploaded successfully!','success').then(ok=>{
                        this.clearSidekiqProcess(); 
                    })
                }else{
                    Swal.fire('success','Duplicate records deleted successfully!','success').then(ok=>{
                        this.clearSidekiqProcess(); 
                    })
                }
                
            } 
            if(!data['success'] && data['status'] == 'failed'){
                this.spinner.hide();
                this.sharedService.removeSideKiqId();
                clearInterval(this.uploading);
                if(obj.processName == 'file_upload'){
                    Swal.fire('Error!','File upload failed!','error').then(ok=>{
                        this.clearSidekiqProcess(); 
                    }) 
                }else{
                    Swal.fire('Error!','Delete duplicate records failed!','error').then(ok=>{
                        this.clearSidekiqProcess(); 
                    }) 
                }
                               
               
            }    
            },err=>{
                this.spinner.hide();
                this.sharedService.removeSideKiqId();
                clearInterval(this.uploading);
                if(obj.processName == 'file_upload'){
                    Swal.fire('Error!','File upload failed!','error').then(ok=>{
                        this.clearSidekiqProcess(); 
                    }) 
                }else{
                    Swal.fire('Error!','Delete duplicate records failed!','error').then(ok=>{
                        this.clearSidekiqProcess(); 
                    }) 
                }
            })
        }
    }
    clearSidekiqProcess(){
        this.spinner.show();
        this.sharedService.updateLoaderEvent({message:'Please wait...'});
        clearInterval(this.uploading);
        this.sharedService.removeSideKiqId();
        this.loadFileData();
       
    }
    addYearAndMonthList(){
        let currentYear = new Date().getFullYear();
        let startYear = 2000;
        let arr = [{name:2000,value:2000}];
        while(true){
            startYear = startYear + 1;
            if(startYear <= currentYear){
                arr.push({name:startYear,value:startYear});
            }else{
                break;
            }
        }
        this.bill_year = arr;
        for(let i=1;i<=12;i++){
            this.bill_month.push({name:i,value:i});
        }
    }
    adjustCssDropDown(){
          setTimeout(()=>{
            $('.ui-button-icon-left ui-clickable').removeClass('pi pi-chevron-down');
            $('.ui-button-icon-left ui-clickable').addClass('pi pi-download');
          },0)
       
    }
    getUniqueFilterColumns(){
        this.fileService.getColumnFilters().subscribe(res=>{
            let main_category_arr = res['data'].values['main_category'];
            let sub_category_arr = res['data'].values['sub_category'];
            let billed_type_arr = res['data'].values['billed_type'];
            let conumer_arr = res['data'].values['conumer_connection_status'];
            let meter_flag_arr = res['data'].values['meter_flag'];
            this.sharedService.unique_filter_columns = res['data'].values;
            if(main_category_arr){
            main_category_arr.forEach(dt=>{
                let nm = '';
                nm = dt;
                if(nm.length > 25){
                    nm = nm.substring(0,25)+'...';
                }
                this.filter_columns.main_category.push({name:nm,value:dt});
                this.filter_columns.tariff.push({name:nm,value:dt});
            })
            }
            if(sub_category_arr){
                sub_category_arr.forEach(dt=>{
                    let nm = '';
                    nm = dt;
                    if(nm.length > 25){
                        nm = nm.substring(0,25)+'...';
                    }
                    this.filter_columns.sub_category.push({name:nm,value:dt});
                })
            }
            if(meter_flag_arr){
                meter_flag_arr.forEach(dt=>{
                    let nm = '';
                    nm = dt;
                    if(nm.length > 25){
                        nm = nm.substring(0,25)+'...';
                    }
                    this.filter_columns.meter_flag.push({name:nm,value:dt});
                })
            }
            if(billed_type_arr){
                billed_type_arr.forEach(dt=>{
                    let nm = '';
                    nm = dt;
                    if(nm.length > 25){
                        nm = nm.substring(0,25)+'...';
                    }
                    this.filter_columns.billed_type.push({name:nm,value:dt});
                })
            }
            if(conumer_arr){
                conumer_arr.forEach(dt=>{
                    let nm = '';
                    nm = dt;
                    if(nm.length > 25){
                        nm = nm.substring(0,25)+'...';
                    }
                    this.filter_columns.conumer_connection_status.push({name:nm,value:dt});
                })
            }
            
        })
    }
    toggleDataDropdown() {
        let dropdown_btn = document.getElementsByClassName('data-dropdown')[0];
        dropdown_btn.classList.toggle('data-dropdown-open');
    }

    setFileType(){
        this.per_page = 10;
        this.page_no = 1;
        this.clearFilter();
        this.filterToggle = false;
        let filterObj = this.filter;
        this.getCustomerBillingData(filterObj);
    }
    filterInputToggle() {
        this.filterToggle = !this.filterToggle;
    }

    toggleFilterCheck() {
        this.filter_hasCheck = !this.filter_hasCheck;
    }
    toggleSelectColumn(){
        this.filter_SelectCol = !this.filter_SelectCol;
    }
    onChangeColumn(col){
            this.tableColumns.map(dt=>{
            if(dt.title == col.title){
                dt.toggle = col.toggle;
            }
         })
        //this.tableColumns.sort(this.sortByProperty('title')); 
        
    }

    getPage(page: number) {
        this.page_no = page;
        let filterObj = this.filter;
        this.getCustomerBillingData(filterObj);
    }

    applyPaginationStyle(){
        setTimeout(()=>{
            $('.ngx-pagination').css('margin-left','-50px');
        },0);
    }

    getCustomerBillingData(filterObj){
        this.filteredList = [];
        this.tableColumns = [];
        this.selectedColumns = [];
        this.exportAllToggle = false;
        this.selectAllCheck = false;
        this.sortBy = [];
        this.rowsSelected = 0;
        this.total_count = 0;
        if(this.exportAllToggle){
            this.deSelectAllRows();
        }
        this.spinner.show();
        $('.dashboard-inner-wrapper').css('height','100vh');
        this.fileService.getCustomerBillingRecords(this.per_page,this.page_no,this.file_type.name,this.globalSearch, filterObj).subscribe(res=>{
            let ds = res['data'].customer_billing_detail;
            this.filteredList = ds;
            if(this.filteredList.length > 0){
                $('.dashboard-inner-wrapper').css('height','100%');
                this.paginationToggle = true;
                this.total_count = res['data'] && res['data'].pagination ? res['data'].pagination['total_count'] : this.filteredList.length;
                let dd = res['data'].customer_billing_detail[0];
                Object.keys(dd).map((dt,i)=>{
                    if(dt != 'id'){
                        this.tableColumns.push({title:dt,toggle:true});
                        this.selectedColumns.push({title:dt,toggle:true});
                    }
                })
                this.toggle = true;
                this.getSortByColumns();
                setTimeout(()=>{
                    $('.ngx-pagination').css('margin-left','-50px');
                    },0);
                 }else{
                this.total_count = 0;
                this.paginationToggle = false;
                $('.dashboard-inner-wrapper').css('height','100vh');
            }
            this.spinner.hide();
           
        },err=>{
            this.spinner.hide();
        })
    }

    getSortByColumns(){
        this.sortBy = [];
        this.tableColumns.map(dt=>{
            if(dt.title == 'bill_issue_date' || dt.title == 'bill_due_date' || dt.title == 'approved_date' || dt.title == 'approval_date'){
                this.sortBy.push({name:dt.title,value:dt.title});
            }
        })
    }

    sortByDate(){
        this.filteredList.sort((a,b)=> new Date(a[this.sortByName]).getTime() - new Date(b[this.sortByName]).getTime());
    }

    sortByProperty(property){
        return function(x,y){
            return ((x[property] === y[property]) ? 0 : ((x[property] > y[property]) ? 1 : -1))
        }
    }

    perPageChanged(){
        let filterObj = {filter_obj:this.filter}
        this.getCustomerBillingData(filterObj);
      }

    selectedFile(files: FileList){
        this.fileToUpload = [];
        this.fileCount = undefined;
        if(Object.keys(files).length > 12){
            Swal.fire('Oops!','Max 12 files are allowed at a time','warning');
            this.fileToUpload = [];
            return;
        }
        Object.keys(files).map(item=>{
            this.fileToUpload.push(files[item]);
        })
        this.sharedService.fileToUpload = this.fileToUpload;
        this.sharedService.file_type = this.selectedFileType;
        this.fileName = this.fileToUpload.name;
        this.per_page = 10;
        this.fileCount = this.fileToUpload.length;
        this.fileText = 'file selected'
        
    }
    deleteFile(file){
        if(this.fileToUpload.length > 0){
            this.fileToUpload.map((dt,i)=>{
                if(file.name == dt.name){
                    this.fileToUpload.splice(i,1);
                }
            })
            this.fileCount = this.fileToUpload.length;
            if(this.fileToUpload.length == 0){
                this.fileCount = undefined;
                this.fileText = 'Choose File'
                this.selectedImportFile = '';
            }
        }
    }
    refresh(){
        let filterObj = this.filter;
        this.getCustomerBillingData(filterObj);
    }
    showImportModal(){
        this.error = '';
        this.fileToUpload = [];
        this.selectedImportFile = '';
        this.fileCount = undefined;
        this.fileText = 'Choose File';
        this.modal.open();
    }
    checkFileType(){
        this.error = '';
        let toggle;
        if(this.fileToUpload.length == 0){
            this.error = 'Please select a file to upload';
            return false;
        }
        this.fileToUpload.map((item,i)=>{
            if(item.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && 
                item.type != "application/vnd.ms-excel.sheet.binary.macroEnabled.12" &&
                item.type != "application/vnd.ms-excel.sheet.binary.macroEnabled.13" &&
                item.type != "application/vnd.ms-excel.sheet.binary.macroEnabled.15" &&
                item.type != "application/vnd.ms-excel" &&
                item.type != "text/csv" &&
                item.type != "application/pdf"){
                toggle = true;
                    
            }
            
        })
        if(toggle){
            this.error = 'Only csv, excel and pdf files can be imported.'
            return false;
            
        }else{
            this.error = '';
            return true;  
        }
       
    }

    importData(){
        if(this.checkFileType()){
            this.router.navigate(['/upload-process']);
        }
    }

    showDeleteModal(){
        $('#myModal').css('display','flex');
    }

    hideDeleteModal(){
        $('#myModal').css('display','none');
    }

    toggleDatePicker(){
        this.datePickerToggle = true;
        if(this.selectedBillDate != ''){
            this.datePickerToggle = false;
        }
    }

    exportAsXLSX():void {
        this.toggleDataDropdown();
        let data = [];
        data = Object.assign([],JSON.parse(JSON.stringify(this.filteredList)));
        data.forEach((dt,i)=>{
            if(typeof(dt.id) === 'object'){
                delete dt.id;
            }
        })
        this.excelService.exportAsExcelFile(data, 'customer_billing_details');
     }

     downloadCSV(){
        this.toggleDataDropdown();
        this.csvOptions.headers = [];
        Object.keys(this.filteredList[0]).map(dt=>{
            if(dt != 'id'){
                this.csvOptions.headers.push(dt);
            }
        })
        let data = [];
        data = Object.assign([],JSON.parse(JSON.stringify(this.filteredList)));
        data.forEach((dt,i)=>{
            if(typeof(dt.id) === 'object'){
                delete dt.id;
            }
        })
        new  AngularCsv(data, "customer_billing_details", this.csvOptions);
      }

    deleteFileRecord(id){
        let ids = [];
        this.delete_all = false;
        ids.push(id)
        Swal.fire({
            title:'Are you sure?',
            text:'Do you really want to delete this records? This process cannot be undone.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete!'
        }).then((result) => {
            if(result.value){
            this.spinner.show();
            this.fileService.deleteFileRecord(this.file_type.name,ids,this.delete_all).subscribe((data: any) => {
                this.spinner.hide();
                Swal.fire('Success!', 'Records has been deleted!', 'success').then(ok=>{
                    let filterObj = this.filter;
                    this.getCustomerBillingData(filterObj);
                });
            },err=>{
                this.spinner.hide();
                Swal.fire('Oops...', 'Record already deleted', 'warning').then(ok=>{
                let filterObj = this.filter;
                this.getCustomerBillingData(filterObj);
                });
            })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
    
        }
        })
    }

    editRecord(item){
        this.sharedService.editFileData = [];
        Object.keys(item).map(dt=>{
            if(dt == 'bill_issue_date' || 
                dt == 'bill_due_date' ||
                dt == 'past_reading_date' ||
                dt == 'present_reading_date' ||
                dt == 'approval_date' ||
                dt == 'approved_date' ||
                dt == 'date_of_connection' ||
                dt == 'date_of_payment' ||
                dt == 'disconnection_date'){
                 if(item[dt] && item[dt] != '' && item[dt] != null && item[dt] != 'null'){
                    item[dt] = new Date(item[dt]);
                 }
                 if(item[dt] == '' || item[dt] == null || item[dt] == 'null' || item[dt] == 'Invalid Date'){
                    item[dt] = undefined;
                 }         
            }
        })
        this.sharedService.editFileData.push(item);
        this.router.navigate(['/record/edit/'+this.file_type.name]);
    }

    viewRecord(item){
        this.sharedService.editFileData = [];
        Object.keys(item).map(dt=>{
            if(dt == 'bill_issue_date' || 
                dt == 'bill_due_date' ||
                dt == 'past_reading_date' ||
                dt == 'present_reading_date' ||
                dt == 'approval_date' ||
                dt == 'approved_date' ||
                dt == 'date_of_connection' ||
                dt == 'date_of_payment' ||
                dt == 'disconnection_date'){
                 if(item[dt] && item[dt] != '' && item[dt] != null && item[dt] != 'null'){
                    item[dt] = new Date(item[dt]);
                 } 
                 if(item[dt] == '' || item[dt] == null || item[dt] == 'null' || item[dt] == 'Invalid Date'){
                    item[dt] = undefined;
                 }   
                
            }
        })
        this.sharedService.editFileData.push(item);
        this.router.navigate(['/record/view/'+this.file_type.name]);
    }

    deleteAll() {
        let userTypeList = this.filteredList;
        userTypeList = userTypeList.filter(a =>
            a.checked === true
        );
        userTypeList = userTypeList.map(data => {
            return data.id
        });
        if (userTypeList.length == 0) {
             Swal.fire('Oops...', 'Please select atleast one record to delete', 'warning');
        } else {
            let ids = [];
            userTypeList.map(dt=>{
                ids.push(dt.$oid);
            })
            let record_msg = '';
            record_msg = userTypeList.length;
            if(this.delete_all){
                record_msg = 'all';
            }
            Swal.fire({
                title:'Are you sure?',
                text:'Do you really want to delete '+record_msg+' records? This process cannot be undone.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete!'
            }).then((result) => {
                if(result.value){
                this.spinner.show();    
                this.fileService.deleteFileRecord(this.file_type.name,ids,this.delete_all).subscribe((data: any) => {
                    this.spinner.hide();
                    Swal.fire('Success!', 'Records has been deleted!', 'success').then(ok=>{
                        let filterObj = this.filter;
                        this.selectAllCheck = false;
                        this.getCustomerBillingData(filterObj);
                    });
                },err=>{
                    this.spinner.hide();
                    Swal.fire('Oops...', err.error.errors, 'warning').then(ok=>{
                        let filterObj = this.filter;
                        this.selectAllCheck = false;
                        this.getCustomerBillingData(filterObj);
                    });
                })
            } else if (result.dismiss === Swal.DismissReason.cancel) {
    
            }
            })
            
            }
    }

    selectCheckBox(value,value2) {
        let typeValue = value;
        let userTypeList = this.filteredList;
        if(typeValue == 0){
            this.selectAllCheck = (!this.selectAllCheck) ? true : false;
        }
        if (this.selectAllCheck && value2 == 'multi') {
            userTypeList.forEach(data => {
                data.checked = true;
            });
        } else if (!this.selectAllCheck && value2 == 'multi') {
            this.deSelectAllRows();
            userTypeList.forEach(data => {
                data.checked = false;
            });
        } else {
            userTypeList.forEach(data => {
                if (data.id['$oid'] == typeValue) {
                    data.checked = (!data.checked) ? true : false;
                }
            });
        }
        let typeList = [];
        this.fileIds = [];
        typeList = userTypeList;
        typeList = typeList.filter(a =>
            a.checked === true
        );
        this.rowsSelected = typeList.length;
        typeList.map(dt=>{
            if(dt && dt.id['$oid']){
                this.fileIds.push(dt.id['$oid']);
            }
        })
    }

    setDate(date:Date,dateType){
        if(dateType == 'approved_date'){
          date.setTime(date.getTime() - (date.getTimezoneOffset()*60*1000));
           this.approved_date = date;
        }
        if(dateType == 'fromDate'){
            date.setTime(date.getTime() - (date.getTimezoneOffset()*60*1000));
             this.fromDate = date;
          }
          if(dateType == 'toDate'){
            date.setTime(date.getTime() - (date.getTimezoneOffset()*60*1000));
             this.toDate = date;
          }
    }

    applyFilter(){
        this.dateError = undefined;
        this.revised_month_error = undefined;
        this.revised_year_error = undefined;
        if(this.file_type.name == 'IBM' || this.file_type.name == 'IBM-ELINK'){
            if(this.toDate < this.fromDate){
                this.dateError = 'End date should be greater than start date';
                return false;
            }
            if(!this.scno && 
                (this.selectedMainCategories.length == 0) &&
                (this.selectedSubCategories.length == 0) &&
                (this.selectedMeterFlags.length == 0) &&
                (this.selectedBilledTypes.length == 0) &&
                (this.selectedConumerConnectionstatus.length == 0) &&
                (this.selectedBilledTypes.length == 0) &&
                !this.selectedBillDate && 
                (this.selectedBillYear.length == 0) && 
                (this.selectedBillMonth.length == 0)){
                        Swal.fire('Opps..','Minimum one field should be selected to apply filter','warning');
                        return false;
                    }
            if(this.selectedBillMonth.length > 0){
                if(this.selectedBillYear.length == 0){
                    Swal.fire('Opps..','Bill year should be selected','warning');
                    return false;
                }
            }
            if(this.selectedBillDate){
                if(!this.fromDate || !this.toDate){
                    Swal.fire('Oops..','From and To dates are required','warning');
                    return false;
                }
            }        
            this.filterToggle = false;
            this.filter_class = true;
            let main = [];
            let sub = [];
            let meter = [];
            let billed_type = [];
            this.filter = {};
            let conumer_status = [];
            let bill_year = [];
            let bill_month = [];
            this.selectedMainCategories.map(dt=>{
                main.push(dt.value);
            })
            this.selectedSubCategories.map(dt=>{
                sub.push(dt.value);
            })
            this.selectedMeterFlags.map(dt=>{
                meter.push(dt.value);
            })
            this.selectedBilledTypes.map(dt=>{
                billed_type.push(dt.value);
            })
            this.selectedConumerConnectionstatus.map(dt=>{
                conumer_status.push(dt.value);
            })
            this.selectedBillYear.map(dt=>{
                bill_year.push(dt.value);
            })
            this.selectedBillMonth.map(dt=>{
                bill_month.push(dt.value);
            })
            this.filter = {scno:this.scno,main_category:main,sub_category:sub,meter_flag:meter,conumer_connection_status:conumer_status ,billed_type:billed_type,billyear:bill_year,billmonth:bill_month,bill_date:{type:this.selectedBillDate,from:this.fromDate,to:this.toDate}};
            let filterObj = this.filter
            this.getCustomerBillingData(filterObj);
        }
        if(this.file_type.name == 'E-LINK'){
            if(this.toDate < this.fromDate){
                this.dateError = 'End date should be greater than start date';
                return false;
            }
            if(!this.consumerNo && 
                (this.selectedTariffs.length == 0) &&
                (this.selectedSubCategories.length == 0) &&
                (this.selectedMeterFlags.length == 0) &&
                (this.selectedBilledTypes.length == 0) &&
                (this.selectedConumerConnectionstatus.length == 0) &&
                (this.selectedBilledTypes.length == 0) &&
                !this.selectedBillDate){
                        Swal.fire('opps..','Minimum one field should be selected to apply filter','warning');
                        return false;
            }
            if(this.selectedBillDate){
                if(!this.fromDate || !this.toDate){
                    Swal.fire('Oops..','From and To dates are required','warning');
                    return false;
                }
            }
            this.filterToggle = false;
            this.filter_class = true;
            let tariff = [];
            let sub = [];
            let meter = [];
            let billed_type = [];
            this.filter = {};
            let conumer_status = [];
            this.selectedConumerConnectionstatus.map(dt=>{
                conumer_status.push(dt.value);
            })
            this.selectedTariffs.map(dt=>{
                tariff.push(dt.value);
            })
            this.selectedSubCategories.map(dt=>{
                sub.push(dt.value);
            })
            this.selectedMeterFlags.map(dt=>{
                meter.push(dt.value);
            })
            this.selectedBilledTypes.map(dt=>{
                billed_type.push(dt.value);
            })
            this.filter = {consumer_no:this.consumerNo,conumer_connection_status:conumer_status,tariff:tariff,sub_category:sub,meter_flag:meter,billed_type:billed_type,bill_date:{type:this.selectedBillDate,from:this.fromDate,to:this.toDate}};
            let filterObj = this.filter;
            this.getCustomerBillingData(filterObj);
        }
        if(this.file_type.name == 'BILL_ADJUSTMENT'){
            if(this.revised_month != '0' && !this.revised_month && 
                this.revised_year != '0' && !this.revised_year &&
                !this.adjustment_remark){
                        Swal.fire('opps..','Minimum one field should be selected to apply filter','warning');
                        return false;
            }
            if(this.revised_year || this.revised_year == '0'){
                let currentYear = new Date().getFullYear();
                if(JSON.parse(this.revised_year) <= 2000 || JSON.parse(this.revised_year) > currentYear){
                    this.revised_year_error = 'Revised Year should be greater than 2000 and can not greater than current year';
                    return false;
                }
                
            }
            if(this.revised_month || this.revised_month == '0'){
                if(JSON.parse(this.revised_month) < 1 || JSON.parse(this.revised_month) > 12){
                    this.revised_month_error = 'Revised Month should be between 1 to 12';
                    return false;
                }
            }
            this.filterToggle = false;
            this.filter_class = true;
            this.filter = {};
            this.filter = {revised_month:this.revised_month,revised_year:this.revised_year,remark:this.adjustment_remark};
            let filterObj = this.filter;
            this.getCustomerBillingData(filterObj);
            
        }
        if(this.file_type.name == 'BILL_REVISION'){
            if(!this.approved_date && 
                !this.revision_remark){
                        Swal.fire('opps..','Minimum one field should be selected to apply filter','warning');
                        return false;
            }
            this.filterToggle = false;
            this.filter_class = true;
            this.filter = {};
            this.filter = {approved_date:this.approved_date,remark:this.revision_remark};
            let filterObj = this.filter;
            this.getCustomerBillingData(filterObj);
        }
        this.clearFilterToggle = false;

    }

    clearFilter(){
        this.filter = {};
        this.datePickerToggle = true;
        this.filter_class = false;
        this.selectedMainCategories = [];
        this.selectedSubCategories = [];
        this.selectedConumerConnectionstatus = [];
        this.selectedMeterFlags = [];
        this.selectedBilledTypes = [];
        this.selectedBillDate = '';
        this.selectedTariffs = [];
        this.consumerNo = '';
        this.scno = '';
        this.fromDate = undefined;
        this.toDate = undefined;
        this.filterToggle = false;
        this.revised_month = '';
        this.revised_year = '';
        this.selectedBillYear = [];
        this.selectedBillMonth = [];
        this.adjustment_remark = '';
        this.revision_remark = '';
        let filterObj = {filter_obj:this.filter}
        this.getCustomerBillingData(filterObj);
        this.clearFilterToggle = true;
    }

    selectToggle(value) {
        let typeValue = value;
        let userTypeList = this.filteredList;
        if (typeValue == 0) {
            this.selectAllCheck = false;
            userTypeList.forEach(data => {
                data.checked = false;
            });
        } else {
            this.selectAllCheck = true;
            userTypeList.forEach(data => {
                    data.checked = true;
                });
        }
        let typeList = [];
        this.fileIds = [];
        typeList = userTypeList;
        typeList = typeList.filter(a =>
            a.checked === true
        );
        this.rowsSelected = typeList.length;
        typeList.map(dt=>{
            if(dt && dt.id['$oid']){
                this.fileIds.push(dt.id['$oid']);
            }
        })
    }


    selectAllRows(){
        if(this.filteredList.length == 0){
            Swal.fire('opps..','No records found to select','warning');
            return;
        }
        this.exportAllToggle = true;
        this.delete_all = true;
        this.selectToggle(1);
    }
    deSelectAllRows(){
        this.per_page = 10;
        this.exportAllToggle = false;
        this.delete_all = false;
        this.selectToggle(0);
        this.applyPaginationStyle();
    }

    exportData(){
        let export_file_type = this.selectedExportFile.name;
        setTimeout(()=>{
            this.selectedExportFile = undefined;
        },0)
        let filterObj = this.filter;
        this.fileService.exportData(this.per_page,this.page_no,this.file_type.name,export_file_type,filterObj).subscribe(res=>{
            Swal.fire('success','Report will be generated and send to your email','success');
           },err=>{
            Swal.fire('Error..!','Something went wrong ,please try again later','error');
        })
    }

    search(){
        if(this.globalSearch.length >=3){
            this.paginationToggle = false;
            let filterObj = this.filter;
            this.getCustomerBillingData(filterObj);
        }
        if(!this.globalSearch || this.globalSearch == ''){
            this.paginationToggle = false;
            let filterObj = this.filter;
            this.getCustomerBillingData(filterObj);
        }
        
    }
    showDuplicateDeleteModal(){
        this.duplicateDeleteModal.open();
    }
    hideDuplicateDeleteModal(){
        this.duplicateDeleteModal.close();
    }

    deleteDuplicate(){
        let formData = new FormData();
        formData.append('type',this.selectedFileTypeForDuplicateDelete);
        this.fileService.deleteDuplicate(formData).subscribe(res=>{
            let message = res['message'];
            console.log(res);
            let sidekiqId = res['data']['job_id'];
            this.sharedService.storeSideKiqId(sidekiqId,'delete_duplicate');
            this.hideDuplicateDeleteModal();
            this.checkFileUploadProcess();
        },err=>{
            Swal.fire('Error!',err.error ? err.error.errors : 'Internal server error','error');
        })
    }

    freeze(){
        $('#body-id').css('pointer-events','none');
    }
    unFreeze(){
        $('#body-id').css('pointer-events','all');
    }
}
