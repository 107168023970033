import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from './services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnDestroy{
  title = 'spml';
  private userEventRef:Subscription = null;
  public loaderMsg:string = 'Please wait...'
  constructor(
    private sharedService:SharedService,
    ){
    
}
  ngOnInit(){
    this.userEventRef = this.sharedService.newLoaderEvent$.subscribe(res=>{
      this.loaderMsg = res['message'];
  })
  }
  ngOnDestroy(){
    if(this.userEventRef){
        this.userEventRef.unsubscribe();
    }
    
  }
}

