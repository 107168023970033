import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Route } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgotPassword.component.html',
    styleUrls: ['./../login/login.component.scss', './forgotPassword.component.scss']
})

export class ForgotPasswordComponent {
    email:string = '';
    errorMsg:string;
    successMsg:string;
    constructor(private userService:UserService){

    }

    forgotPassword(){
       if(this.validateData()){
        this.userService.forgotPassword(this.email).subscribe(res=>{
            Swal.fire('success','Reset password link has been sent to your mail.','success');
        },error=>{
            Swal.fire('Error!',error && error.error && error.error.error ? error.error.error : 'Email address does not exist','error')
        })
       }
    }
    validateData(){
        this.errorMsg = undefined;
        if(!this.email){
           this.errorMsg = "Email address is required";
           return false;
        }
        if(!this.validateEmail(this.email)){
           this.errorMsg = "Email must be formatted correctly";
           return false;
         }
        
        return true;   
    }

    validateEmail(email){
       var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       return reg.test(String(email).toLowerCase());
     }
}
