import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './base/login/login.component';
import { ForgotPasswordComponent } from './base/forgotPassword/forgotPassword.component';
import { ResetPasswordComponent } from './base/resetPassword/resetPassword.component';
import { ProfileComponent } from './base/profile/profile.component';
import { DashboardComponent } from './base/dashboard/dashboard.component';
import { UploadProcess } from './base/uploadProcess/uploadProcess.component';
import { RecordEditComponent } from './base/recordEdit/recordEdit.component';
import { ChangePassword } from './base/changePassword/changePassword.component';
import { NewUserChangePassword } from './base/newUserChangePassword/newUserChangePassword.component';
import { AddUser } from './base/addUser/addUser.component';
import { AuthGuard } from './guard/auth.guard';
import { DownloadList } from './base/download-list/download-list.component';
import { FilterComponent } from './base/filter/filter.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token/:email', component: ResetPasswordComponent },
  { path: 'profile', component: ProfileComponent,canActivate:[AuthGuard] },
  { path: 'dashboard', component: DashboardComponent,canActivate:[AuthGuard] },
  { path: 'upload-process', component: UploadProcess,canActivate:[AuthGuard] },
  { path: 'record/:action/:file_type' ,component: RecordEditComponent,canActivate:[AuthGuard] },
  { path: 'change-password', component: ChangePassword,canActivate:[AuthGuard] },
  { path: 'new-change-password', component: NewUserChangePassword },
  { path: 'add-user', component: AddUser,canActivate:[AuthGuard] },
  { path: 'download-list', component: DownloadList,canActivate:[AuthGuard] },
  {path:'filter',component:FilterComponent,canActivate:[AuthGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
