import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, RouterState } from '@angular/router';
import { Router } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router : Router, private authService:AuthenticateService) { }
  canActivate() {
    if(!this.authService.isTokenExpired()){
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }

}
