import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalModule } from 'angular-custom-modal';
import { HttpClientModule } from '@angular/common/http';
import { CalendarModule} from 'primeng/components/calendar/calendar';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
// Custom Components
import { LoginComponent } from './base/login/login.component';
import { ForgotPasswordComponent } from './base/forgotPassword/forgotPassword.component';
import { ResetPasswordComponent } from './base/resetPassword/resetPassword.component';
import { UploadProcess } from './base/uploadProcess/uploadProcess.component';
import {ProfileComponent} from './base/profile/profile.component';
import {HeaderComponent} from './components/header/header.component';
import {DashboardComponent} from './base/dashboard/dashboard.component';
import { ImportFileModal } from './components/importFileModal/importFileModal.component'
import { AuthenticateService } from './services/authenticate.service';
import { SharedService } from './services/shared.service';
import { UserService } from './services/user.service';
import { FileService } from './services/file.service';
import { ChangePassword } from './base/changePassword/changePassword.component';
import { RecordEditComponent } from './base/recordEdit/recordEdit.component';
import { AddUser } from './base/addUser/addUser.component';
import { NewUserChangePassword } from './base/newUserChangePassword/newUserChangePassword.component';
import { AuthGuard } from './guard/auth.guard';
import { SearchColumnFilterPipe } from './pipes/search-column.pipe';
import { GlobalSearchFilterPipe } from './pipes/global-search.pipe';
import { ExcelService } from './services/excel.service';
import { DownloadList } from './base/download-list/download-list.component';
import { FilterComponent } from './base/filter/filter.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ProfileComponent,
    HeaderComponent,
    DashboardComponent,
    ImportFileModal,
    UploadProcess,
    RecordEditComponent,
    ChangePassword,
    NewUserChangePassword,
    AddUser,
    DownloadList,
    FilterComponent,
    SearchColumnFilterPipe,
    GlobalSearchFilterPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ModalModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    CalendarModule,
    MultiSelectModule,
    DropdownModule,
    NgxSpinnerModule
  ],
  providers: [AuthenticateService,SharedService,UserService,FileService,ExcelService,AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
