import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {SharedService} from './shared.service';
import * as jwt_decode from "jwt-decode";

@Injectable({ providedIn: 'root' })
export class AuthenticateService {
    constructor(private http: HttpClient,private sharedService:SharedService) {
       
    }

    signIn(userObj) {
        return this.http.post<any>(this.sharedService.baseURL+`/users/authenticate`, {user:userObj})
            .pipe(map(result => {
                if (result && result.data && result.data.response && result.data.response.token) {
                    this.setToken(result.data.response.token);
                    this.setCurrentUser(JSON.stringify(result.data.response.user));
                }
                return result;
            }));
    }
    setCurrentUser(currentUser){
        localStorage.setItem('currentUser', currentUser);
    }

    getCurrentUser(){
        if(localStorage.getItem('currentUser') && 
            localStorage.getItem('currentUser') != '' && 
            localStorage.getItem('currentUser') != 'undefined'){
            return JSON.parse(localStorage.getItem('currentUser'));   
        }else{
            localStorage.removeItem('currentUser');
            return undefined;
        }
    }
    setToken(token){
        localStorage.setItem('token',token);
    }
    getToken(){
        if(localStorage.getItem('token') && 
            localStorage.getItem('token') != '' && 
            localStorage.getItem('token') != 'undefined'){
            return localStorage.getItem('token');   
        }else{
            localStorage.removeItem('token');
            return undefined;
        }
    }
    getTokenExpirationDate(token:string): Date{
        const decoded = jwt_decode(token);
        if(decoded.exp === undefined) return null;
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    isTokenExpired(token?:string):boolean{
        if(!token) token = this.getToken();
        if(!token) return true;
        const date = this.getTokenExpirationDate(token);
        if(date === undefined) return false;

        return !(date.valueOf() > new Date().valueOf());
    }

    logout() {
        return new Promise(function(resolve,reject){
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser');
            localStorage.removeItem('token');
            resolve();
        })
    }
 }