import { Component, OnInit } from '@angular/core';
import { FileService } from 'src/app/services/file.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';
import {Location} from "@angular/common";
declare var $:any;

@Component({
    selector: 'upload-process',
    templateUrl: './uploadProcess.component.html',
    styleUrls: ['./uploadProcess.component.scss']
})

export class UploadProcess implements OnInit{
    fileToUpload:any = [];
    file_type:any;
    file_imported:any;
    progress:any;
    error:any;
    success:any;
    loading:any;
    constructor(
        private sharedService:SharedService ,
        private fileService:FileService,
        private router:Router,
        private location: Location){

    }
    ngOnInit(){
        this.fileToUpload = this.sharedService.fileToUpload;
        this.file_type = this.sharedService.file_type;
        if(this.fileToUpload.length == 0){
           this.router.navigate(['/dashboard']);
            return;
        }
        this.sharedService.preventBackButton();
        this.fileUpload();
    }

    fileUpload(){
        this.freeze();
        this.loader();
        let formData = new FormData(); 
        this.fileToUpload.map((item,i)=>{
            formData.append('file[]', item, item.name); 
        })
        formData.append('file_type',this.file_type);
        this.fileService.uploadFile(formData).subscribe(res=>{
            let sidekiqId = res['data']['job_id'];
            this.sharedService.storeSideKiqId(sidekiqId,'file_upload');
            this.clearLoading();
            this.unFreeze();
            this.router.navigate(['/dashboard']);            
        },err=>{
            this.unFreeze();
            this.clearLoading();
            Swal.fire('Opps..','File uploading failed!','error').then(ok=>{
                this.router.navigate(['/dashboard']);
            });
        })
    }
    
    freeze(){
        $('#body-id').css('pointer-events','none');
    }
    unFreeze(){
        $('#body-id').css('pointer-events','all');
    }
    goToDashboard(){
        this.router.navigate(['/dashboard']);
    }
    loader(){
      this.loading = setInterval(this.startLoader,1000);
        
    }

    startLoader(){
        if($('#id1').hasClass('active')){
            $('#id1').removeClass('active');
            $('#id2').addClass('active');
            $('#id3').removeClass('active');
            return;
        }
        if($('#id2').hasClass('active')){
            $('#id2').removeClass('active');
            $('#id3').addClass('active');
            $('#id1').removeClass('active');
            return;
        }
        if($('#id3').hasClass('active')){
            $('#id3').removeClass('active');
            $('#id1').addClass('active');
            $('#id2').removeClass('active');
            return;
        }
    }

    clearLoading(){
        clearInterval(this.loading);
    }
}