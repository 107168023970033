import { Component ,OnInit} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'add-user',
    templateUrl: './addUser.component.html',
    styleUrls: ['./addUser.component.scss','../profile/profile.component.scss']
})

export class AddUser implements OnInit{
    user:any = {
        first_name:'',
        last_name:'',
        email:''
    }
    errorMsg:any;
    error:any;
    successMsg:any;
    constructor(private userService:UserService){

    }
    ngOnInit(){

    }

    addUser(){
        if(this.validateForm()){
            this.userService.addUser(this.user).subscribe(res=>{
                Swal.fire('success','User has been registred successfully!','success');
                this.resetUser();
            },err=>{
                Swal.fire('Error!', !err.error.success ? 'User already exist' :'Internal Server error','error');
               })
        }
    }

    closeMsg(){
        this.errorMsg = undefined;
        this.successMsg = undefined;
    }

    validateForm(){
        this.error = {};
        if(!this.user.first_name){
          this.error = {value:'first_name',msg:'First Name is required'};
          return false;
        } 
        if(!this.user.first_name.match(/^[a-zA-Z]+$/)){
            this.error = {value:'first_name',msg:'First name must contain only letters'};
           return false;
          } 
        if(!this.user.last_name){
          this.error = {value:'last_name',msg:'Last Name is required'};
          return false;
        }  
        if(!this.user.last_name.match(/^[a-zA-Z]+$/)){
          this.error = {value:'last_name',msg:'Last name must contain only letters'};
         return false;
        }
        if(!this.user.email){
            this.error = {value:'email',msg:'Email address is required'};
            return false;
         }
         if(!this.validateEmail(this.user.email)){
            this.error = {value:'email',msg:'Email must be formatted correctly'};
            return false;
          }
        
        return true;
    
      }

      validateEmail(email){
        var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(String(email).toLowerCase());
      }

    resetUser(){
        this.user = {
            first_name:'',
            last_name:'',
            email:''
        };
    }


}
