import { Component, OnInit, Pipe } from '@angular/core';
import { FileService } from 'src/app/services/file.service';
import { SharedService } from 'src/app/services/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Pipe({name:'keys'})
@Component({
    selector: 'app-record-edit',
    templateUrl: './recordEdit.component.html',
    styleUrls: ['./recordEdit.component.scss', '../../components/importFileModal/importFileModal.component.scss']
})

export class RecordEditComponent implements OnInit{
    inputToggle: boolean = true;
    labelToggle: boolean = false;
    action:any;
    file_type:any;
    formLabels:any = [];
    unique_filter_columns:any = {};
    keys:any;
    toggle:boolean = false;
    editFileData:any = [];
    toggleForm:boolean = false;
    errors:any = [];
    constructor(
        private sharedService:SharedService ,
        private fileService:FileService,
        private router:Router,
        private activeRoute:ActivatedRoute){

    }
    ngOnInit(){
        this.editFileData = this.sharedService.editFileData;
        this.file_type = this.activeRoute.snapshot.paramMap.get('file_type');
        this.sharedService.file_type = this.file_type;
        this.action = this.activeRoute.snapshot.paramMap.get('action');
        this.unique_filter_columns = this.sharedService.unique_filter_columns;
        if(this.unique_filter_columns.length == 0){
            this.router.navigate(['/dashboard']);
            return;
        }
        if(this.editFileData.length == 0){
            this.router.navigate(['/dashboard']);
            return;
        }
        if(this.action == 'edit'){
            this.toggleForm = true;
        }
        this.createLabels();
        
    }

    goToDashboard(){
        this.router.navigate(['/dashboard']);
    }
    changeToggle(){
        this.toggleForm = true;
    }

    createLabels(){
        Object.keys(this.editFileData[0]).map(dt=>{
            if(dt != 'id'){
                this.formLabels.push({title:dt});
            }
        })
    }

    changeInput(fieldName,value){
        let toggle;
        if(!value || value == ''){
            this.errors.map(dt=>{
                if(dt.field == fieldName){
                    toggle = true;
                    dt.toggle = true;
                    dt.msg = 'This field is required';
                }
                if(fieldName == 'mobile_no' && !value.match(/^\d+$/)){
                    dt.toggle = true;
                    dt.msg = 'Mobile no should be 10 digit number';
                }
                if(fieldName == 'mobile_no' && value.length < 10 || value.length > 10){
                    dt.toggle = true;
                    dt.msg = 'Mobile no should be 10 digit number';
                }
            })
            if(!toggle){
                this.errors.push({field:fieldName,toggle:true,msg:'This field is required'});
            }
        }else{
            this.errors.map(dt=>{
                if(dt.field == fieldName){
                    if(fieldName == 'mobile_no' && !value.match(/^\d+$/)){
                        dt.toggle = true;
                        dt.msg = 'Mobile no should be 10 digit number';
                    }
                  if(fieldName == 'mobile_no' && value.length < 10 || value.length > 10){
                        dt.toggle = true;
                        dt.msg = 'Mobile no should be 10 digit number';
                    }else{
                        dt.toggle = false;
                        dt.msg = '';
                    }
                }
            })
        }
       
    }

    setDate(date:Date,key){
          date.setTime(date.getTime() - (date.getTimezoneOffset()*60*1000));
          let data = this.editFileData[0];
          Object.keys(this.editFileData[0]).map(dt=>{
            if(dt == key){
                this.editFileData[0][dt] = date;
            }
        })
        
    }

    save(){
        let data = this.editFileData[0];
        let obj = {};
        let id = '';
        let toggle;
        let field;
        Object.keys(data).map(dt=>{
            if(dt == 'id'){
                id = data[dt]['$oid'];
            }else{
                obj[dt] = data[dt];
            }
        })
        this.errors.map(dt=>{
            if(dt.toggle){
                toggle = true;
                field = dt.field;
            }
        })
        if(!toggle){
            this.fileService.editFileRecord(this.file_type,id,obj).subscribe(res=>{
                Swal.fire('success','Record has been updated','success').then(ok=>{
                    this.router.navigate(['/dashboard']);
                })
            },err=>{
            
            })
        }else{
            Swal.fire('Oops..!',field.toUpperCase()+' is required','warning');
        }
    }
    
}
