import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AuthenticateService } from 'src/app/services/authenticate.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent {
    inputToggle: boolean = false;
    labelToggle: boolean = true;
    user:any = {
        first_name:'',
        last_name:'',
        email:''
    }
    error:any;
    constructor(
        private userService:UserService,
        private authService:AuthenticateService,
        private router:Router,
        private sharedService:SharedService
        ){

    }
    ngOnInit(){
        this.getUserProfile();
    }

    getUserProfile(){
        this.userService.getProfile().subscribe(res=>{
            this.user.first_name = res['data'].user.first_name;
            this.user.last_name = res['data'].user.last_name;
            this.user.email = res['data'].user.email;
        },err=>{
            
        })
    }

    updateUser(){
        if(this.validateForm()){
            this.userService.updateUser(this.user).subscribe(res=>{
                Swal.fire('success','Details has been updated','success');
                this.labelInputToggle();
                this.sharedService.updateEvent({name:this.user.first_name});
                this.getUserProfile();
            },err=>{
                Swal.fire('Opps..','Something went wrong,please try again later','error');
            })
        }
    }

    
    validateForm(){
        this.error = {};
        if(!this.user.first_name){
          this.error = {value:'first_name',msg:'First Name is required'};
          return false;
        } 
        if(!this.user.first_name.match(/^[a-zA-Z]+$/)){
            this.error = {value:'first_name',msg:'First name must contain only letters'};
           return false;
          } 
        if(!this.user.last_name){
          this.error = {value:'last_name',msg:'Last Name is required'};
          return false;
        }  
        if(!this.user.last_name.match(/^[a-zA-Z]+$/)){
          this.error = {value:'last_name',msg:'Last name must contain only letters'};
         return false;
        }
        if(!this.user.email){
            this.error = {value:'email',msg:'Email address is required'};
            return false;
         }
         if(!this.validateEmail(this.user.email)){
            this.error = {value:'email',msg:'Email must be formatted correctly'};
            return false;
          }
        
        return true;
    
      }

      validateEmail(email){
        var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(String(email).toLowerCase());
      }

    labelInputToggle() {
        this.inputToggle = !this.inputToggle;
        this.labelToggle = !this.labelToggle;
    }
}
