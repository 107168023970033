import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticateService } from 'src/app/services/authenticate.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit{
    user:any = {
        email:'',
        password:''
    }
    remember:boolean = false;
    loading:boolean = false;
    error:any;
    currentUser:any;
    constructor(private authService:AuthenticateService,private router:Router){
        
    }
    ngOnInit(){
        if(localStorage.getItem('useremail') && 
            localStorage.getItem('userpassword') &&
            localStorage.getItem('remember')){
            this.user.email = localStorage.getItem('useremail');
            this.user.password = localStorage.getItem('userpassword');
            this.remember = JSON.parse(localStorage.getItem('remember'));    
        }
        this.currentUser = this.authService.getCurrentUser();
        if(this.currentUser){
            this.router.navigate(['/dashboard']);
            return;
        }
    }
    signIn() {
        this.error = '';
        if(this.validateData()){
            this.loading = true;
            this.rememberCredentials();
            if(!this.remember){
                this.removeCredentials();
            }
            this.authService.signIn(this.user)
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate(['/dashboard']);
                },
                error => {
                    this.error = error && error.error && error.error.errors ? error.error.errors : 'Unauthorized user';
                });
        }
        
     }

     validateData(){
         if(this.user && !this.user.email){
            this.error = "Email address is required";
            return false;
         }
         if(!this.validateEmail(this.user.email)){
            this.error = "Email must be formatted correctly";
            return false;
          }
          if(this.user && !this.user.password){
            this.error = "Password is required";
            return false;
         }
         return true;   
     }

     validateEmail(email){
        var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(String(email).toLowerCase());
      }
      rememberCredentials(){
          localStorage.setItem('useremail',this.user.email);
          localStorage.setItem('userpassword',this.user.password);
          localStorage.setItem('remember',JSON.stringify(this.remember));
        }
      removeCredentials(){
          localStorage.removeItem('useremail');
          localStorage.removeItem('userpassword');
          localStorage.removeItem('remember');
      }
}
