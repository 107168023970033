import { Component } from '@angular/core';

@Component({
    selector: 'new-user-change-password',
    templateUrl: './newUserChangePassword.component.html',
    styleUrls: ['./../login/login.component.scss', './newUserChangePassword.component.scss']
})

export class NewUserChangePassword {

}
