import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import { FileService } from 'src/app/services/file.service';

@Component({
    selector: 'download-list',
    templateUrl: './download-list.component.html',
    styleUrls: ['./download-list.component.scss']
})

export class DownloadList implements OnInit{
    fileObj:any = {id:'',file:'',export_date:''};
    fileData:any = [];
    error:any;
    toggle:boolean = true;
    constructor(private fileService:FileService){

    }

    ngOnInit(){
        this.getFileDownloadList();
    }
    getFileDownloadList(){
       this.fileService.getDownloadList().subscribe(res=>{
        this.fileData = res['data']['export_files'];
        this.toggle = true;
       },err=>{
        this.toggle = false;
       }) 
    }
    deleteRecord(itemId){
        this.fileService.deleteDownloadFile(itemId).subscribe(res=>{
            this.getFileDownloadList();
            Swal.fire('success','File has been deleted','success')
        },err=>{
            Swal.fire('Error!','File is not deleted,please try again later','error');
        })
    }

    refresh(){
        this.toggle = false;
        this.fileData = [];
        this.getFileDownloadList();
    }

}