import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-reset-password',
    templateUrl: './resetPassword.component.html',
    styleUrls: ['./../login/login.component.scss', './resetPassword.component.scss']
})

export class ResetPasswordComponent implements OnInit{
    token:string;
    email:string;
    password:string;
    password_confirmation:string;
    errorMsg:string;
    successMsg:string;
    toggle:boolean = false;
    constructor(
        private userservice:UserService,
        private router:Router,
        private activeRoute:ActivatedRoute
        ){
        this.token = this.activeRoute.snapshot.paramMap.get('token');
        this.email = this.activeRoute.snapshot.paramMap.get('email');
        this.validateToken();
    }
  
    ngOnInit(){

    }

    validateToken(){
        this.userservice.validateToken(this.token,this.email).subscribe(res=>{
            this.toggle = true;
        },err=>{
            this.toggle = false;
            this.errorMsg = 'Token has been expired';
        })
    }

    resetPassword(){
        let obj = {email:this.email,password:this.password,password_confirmation:this.password_confirmation};
        this.userservice.resetPassword(this.token,obj).subscribe(res=>{
            this.toggle = false;
            Swal.fire('success','Password has been reset successfully!','success').then(ok=>{
                this.router.navigate(['/']);
            });
        },err=>{
            this.toggle = false;
            Swal.fire('Error!','Something went wrong,please try again later','error').then(ok=>{
                this.router.navigate(['/']);
            });
        })
    }

}
