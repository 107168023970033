import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import {SharedService} from './shared.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private sharedService:SharedService, private http:HttpClient){

    }

    forgotPassword(email:string){
        return this.http.post(this.sharedService.baseURL+'/users/password_resets',{email:email});
    }

    addUser(user){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.post(this.sharedService.baseURL+'/users/sign_up', {registration:user},{headers:headers});
    }

    updateUser(user){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.patch(this.sharedService.baseURL+'/users/update_profile', user,{headers:headers});          
    }

    getProfile(){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.get(this.sharedService.baseURL+'/users/account_profile',{headers:headers});
    }

    validateToken(token,email){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.get(this.sharedService.baseURL+'/users/password_resets/'+token+'/edit?email='+email,{headers:headers});
    }
    resetPassword(token,obj){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.put(this.sharedService.baseURL+'/users/password_resets/'+token, obj,{headers:headers});        
    }

    updatePassword(obj){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.patch(this.sharedService.baseURL+'/users/update_password', obj,{headers:headers});
    }

}







