import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import {SharedService} from './shared.service';
//import {RequestOptions,ResponseContentType} from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class FileService {
    constructor(private sharedService:SharedService, private http:HttpClient){

    }

    uploadFile(obj){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.post(this.sharedService.baseURL+'/items_imports/import',obj, {headers:headers});
    }

    getCustomerBillingRecords(per_page,page_no,file_type,search_text,filterObj){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        let params = new HttpParams().set("filter_object", JSON.stringify(filterObj));
        return this.http.get(this.sharedService.baseURL+'/customer_billing_details?page='+page_no+'&per_page='+per_page+'&file_type='+file_type+'&search_text='+search_text,{params:params,headers:headers});
    }
    deleteFileRecord(file_type,fileIds,delete_all){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        if(delete_all){
            return this.http.request('delete', this.sharedService.baseURL+'/customer_billing_details/multi_destroy?file_type='+file_type+'&delete_all='+delete_all, { body: {ids:[]},headers:headers });        
        }else{
            return this.http.request('delete', this.sharedService.baseURL+'/customer_billing_details/multi_destroy?file_type='+file_type, { body: {ids:fileIds},headers:headers });
        }
    }
    editFileRecord(file_type,fileId,obj){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.put(this.sharedService.baseURL+'/customer_billing_details/'+fileId+'?file_type='+file_type,obj, {headers:headers});
    }
    getColumnFilters(){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.get(this.sharedService.baseURL+'/customer_billing_details/filter_column_uniq_values',{headers:headers}); 
    }

    exportData(per_page,page_no,file_type,export_file_type,filterObj){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        let params = new HttpParams().set("filter_object", JSON.stringify(filterObj));
        return this.http.get(this.sharedService.baseURL+'/export_records/export_record_in_file?page='+page_no+'&per_page='+per_page+'&file_type='+file_type+'&export_file_type='+export_file_type,{params:params,headers:headers});
    }
    getDownloadList(){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.get(this.sharedService.baseURL+'/export_records',{headers:headers});

    }
    deleteDownloadFile(id){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.delete(this.sharedService.baseURL+'/export_records/'+id,{headers:headers});
    }
    checkFileUploadProcessing(sidekiqId){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.get(this.sharedService.baseURL+'/items_imports/sidekiq_job_status/'+sidekiqId,{headers:headers}); 
    }
    updateFilter(obj){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.post(this.sharedService.baseURL+'/customer_billing_details/save_unique_values_of_filters',obj, {headers:headers});
    }
    deleteDuplicate(obj){
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('token'));
        return this.http.post(this.sharedService.baseURL+'/customer_billing_details/delete_duplicate',obj,{headers:headers});
    }
}