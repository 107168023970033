import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { LocationStrategy } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class SharedService {
    currentUser:any;
    fileToUpload:any = [];
    file_type:any;
    file_imported:any;
    editFileData:any = [];
    unique_filter_columns:any = {};
    private event = new Subject<void>();
    public newEvent$ = this.event.asObservable();
    private loaderEvent = new Subject<void>();
    public newLoaderEvent$ = this.loaderEvent.asObservable();
    // baseURL:string = 'https://api-spml-staging.priceyouridea.com/api/v1'; //localhost replaced by server ip
    baseURL:string = environment.baseURL;
    
    constructor(
        private locationStrategy: LocationStrategy
      ) { }

    updateEvent(obj){
        this.event.next(obj);
    }
    updateLoaderEvent(obj){
      this.loaderEvent.next(obj);
  }
    // Define a function to handle back button and use anywhere
    preventBackButton() {
        history.pushState(null, null, location.href);
        this.locationStrategy.onPopState(() => {
          history.pushState(null, null, location.href);
        })
      }

      storeSideKiqId(sideKiqId,processName){
        localStorage.setItem('sidekiqId',sideKiqId);
        localStorage.setItem('processName',processName);
      }
      removeSideKiqId(){
        localStorage.removeItem('sidekiqId'); 
        localStorage.removeItem('processName');  
      }
      getSideKiqId(){
        return {processName:localStorage.getItem('processName'),sidekiqId:localStorage.getItem('sidekiqId')};
      }

}
