import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticateService } from 'src/app/services/authenticate.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit,OnDestroy{
    currentUser:any;
    private userEventRef:Subscription = null;
    constructor(
        private authService:AuthenticateService,
        private router:Router,
        private sharedService:SharedService,
        private userService:UserService
        ){
        
    }

    ngOnInit(){
        this.currentUser = this.authService.getCurrentUser();
        if(!this.currentUser){
            this.router.navigate(['/']);
            return;
        }
        this.userEventRef = this.sharedService.newEvent$.subscribe(res=>{
            this.currentUser.first_name = res['name'];
        })
        this.getUserProfile();
    }
    ngOnDestroy(){
        if(this.userEventRef){
            this.userEventRef.unsubscribe();
        }
        
    }

    getUserProfile(){
        this.userService.getProfile().subscribe(res=>{
            this.currentUser.first_name = res['data'].user.first_name;
        },err=>{
            
        })
    }

    toggleProfileDropdown(){
        let dropdown_btn = document.getElementsByClassName('dropdown-outer')[0];
        dropdown_btn.classList.toggle('profile-dropdown-open');
    }
    logout(){
        this.authService.logout().then(res=>{
          this.router.navigate(['/']);
        }).catch(err=>{
          this.router.navigate(['/']);
        });
        
      }
}
